import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFacade } from '@domains/auth/auth.facade';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

@Injectable()
export class TokenAppendGbizIdInterceptorService implements HttpInterceptor {
  private auth: AuthFacade;

  constructor(private injector: Injector, @Inject('API_URL') private apiUrl: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // apiUrlでない場合は無視する
    if (!req.url.startsWith(this.apiUrl)) {
      return next.handle(req);
    }

    // get it here within intercept
    this.auth = this.injector.get(AuthFacade);

    const token = this.auth.gBizToken.access_token;
    // アクセストークンがなければ無視する
    if (!token) {
      return next.handle(req);
    }

    const delegatdUserId = this.auth.delegateUserId;

    if (0 <= delegatdUserId) {
      // 代理ログイン中の場合
      const tokenReq = req.clone({
        url: req.url,
        setHeaders: { Authorization: `Bearer ${token}`, DelegatorUserId: delegatdUserId.toString() }, // HTTP HeaderにdelegateUserIdを追加
        responseType: req.responseType || 'json',
      });

      return next.handle(tokenReq);
    } else {
      // 代理ログイン中でない場合
      const tokenReq = req.clone({
        url: req.url,
        setHeaders: { Authorization: `Bearer ${token}` },
        responseType: req.responseType || 'json',
      });

      return next.handle(tokenReq);
    }
  }
}
