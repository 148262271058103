import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Inject } from '@angular/core';

@Injectable()
export class ApiCallSpinnerDriveInterceptorService implements HttpInterceptor {
  private spinnerCounter = 0;

  constructor(private spinner: NgxSpinnerService, @Inject('API_URL') private apiUrl: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // apiでない 且つ PUT（S3アクセスのファイルアップロードを想定）でもないものは無視する
    if (!req.url.startsWith(this.apiUrl) && req.method !== 'PUT') {
      return next.handle(req);
    }

    // show spinner
    if (this.spinnerCounter === 0) {
      this.spinner.show();
    }
    this.spinnerCounter++;

    // send http request
    return next
      .handle(req)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          console.log(e);

          let displayedErrorMessageId = 'MESSAGE.DEFAULT_ERROR';

          switch (e.status) {
            case 401:
              // ステータス401は、JWTトークンエラー
              displayedErrorMessageId = 'MESSAGE.TOKEN_IS_EXPIRED';
              break;
            case 500:
              // ステータス500は、❞Error: {メッセージID}❞になっているため[Error: ]をTranslate用に[MESSAGE.]に変換する
              displayedErrorMessageId = e.error.replace(/^Error: /g, 'MESSAGE.');
              break;
          }

          return throwError(new Error(displayedErrorMessageId));
        })
      )
      .pipe(
        finalize(() => {
          if (this.spinnerCounter > 0) {
            this.spinnerCounter--;
          }
          if (this.spinnerCounter === 0) {
            this.spinner.hide();
          }
        })
      );
  }
}
