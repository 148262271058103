import { Component, OnInit } from '@angular/core';
import { MetaService } from '@shared/services/meta/meta.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthFacade } from '@domains/auth/auth.facade';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private metaService: MetaService,
    private translate: TranslateService,
    private router: Router,
    private authFacade: AuthFacade
  ) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('ja');
    this.translate.use('ja');

    moment.locale('ja');

    this.metaService.injectMetaTag();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkUrl(event.urlAfterRedirects);
    });
  }

  checkUrl(currentUrl: string) {

    // 代理ログイン中は特定の画面へのアクセスを禁止する。
    const canDelegationLoggedInUrls = [
      '/account/edit', // 自社情報の確認・編集画面
      '/request-project/list', // マイページ
      '/consult/list' // お問い合わせ一覧
    ]

    canDelegationLoggedInUrls.forEach((url: string) => {
      if (currentUrl.includes(url))
        // 禁止した画面にアクセスした場合は強制的に代理ログアウトする。
        this.authFacade.delegateUserId = -1;
    });
  }
}