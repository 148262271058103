import { createAction, props } from '@ngrx/store';
import { IGbizDelegationInfo, IGbizDelegationUserInfo, IGbizUserInfo } from '@interface/gbiz.interface';

export const setAuth = createAction(
  '[Auth API] Set Auth State',
  props<{
    loggedIn: boolean;
    userProfile: IGbizUserInfo;
    delegateLoggedIn: boolean;
    delegateUserId: number;
    delegateUser: IGbizDelegationUserInfo;
    delegationInfo: IGbizDelegationInfo;
  }>()
);