import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from '@shared/store/app-store.module';
import { AuthStoreModule } from '@domains/auth/store/auth-store.module';
import { LoadingModule } from '@organisms/loading/loading.module';
import { CallbackModule } from '@pages/callback/callback.module';
import { ErrorHandlerModule } from '@shared/error-handler/error-handler.module';
import { LogoutModule } from '@pages/logout/logout.module';

registerLocaleData(localeJa);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule,
    AppRoutingModule,
    AppStoreModule,
    AuthStoreModule,
    LoadingModule,
    CallbackModule,
    LogoutModule,
    ErrorHandlerModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: 'API_URL', useValue: '/api' },
    { provide: LOCALE_ID, useValue: 'ja-JP' },
  ],
})
export class AppModule {}
