import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@domains/auth/auth.facade';
import { CompanyFacade } from '@domains/company/company.facade';
import { IGbizDelegationUserInfo } from '@interface/gbiz.interface';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(private router: Router, private auth: AuthFacade, private company: CompanyFacade) { }

  private STATE_PARAM = 'state';
  ngOnInit() {
    console.log('[CallbackComponent] OnInit');

    console.log(this.auth.gBizConfig);

    const code = this.getQueryString('code');
    const state = this.getQueryString(this.STATE_PARAM)?.replace(/\?.*$/, ''); // URLからクエリーパラメータを除外
    const params = this.getRouterParams(); // 別途クエリパラメータを取得

    this.auth
      .handleAuthentication(code)
      .then((gBizUserInfo) => this.company.saveGBizUserInfo(gBizUserInfo))
      .then(() => this.auth.saveDelegationInfo())
      .then(() => {
        // 委任元のUser情報をServer側に保存する。
        this.auth.delegationInfo?.delegation_info?.forEach((delegationUserInfo: IGbizDelegationUserInfo) => {
          const gbizInfo = this.auth.convertToGbizUserInfo(delegationUserInfo);
          this.company.saveGBizUserInfo(gbizInfo);
        });
      })
      .then(() => this.router.navigate([state], params));
  }

  getQueryString(key: string) {
    if (location.search.length < 1) {
      return '';
    }

    const parameters = location.search.substring(1).split('&');
    const targetParam = parameters.filter((p) => p.startsWith(`${key}=`));
    if (targetParam.length === 0) {
      return '';
    }

    return decodeURIComponent(targetParam[0].split('=')[1]);
  }

  getRouterParams() {
    if (location.search.length < 1) return {};

    // state部分を抽出
    const stateIdx = location.search.indexOf(this.STATE_PARAM);
    if (stateIdx < 0) return {};
    const state = decodeURIComponent(location.search.substring(stateIdx, location.search.length));

    // state内のクエリストリングを取得
    const paramIdx = state.indexOf('?');
    if (paramIdx < 0) return {};

    const parameters = state.substring(paramIdx + 1, state.length);
    const paramArray = parameters.split('&');

    // オブジェクトへ整形
    const queryParams = {};
    for (let i = 0; i < paramArray.length; i++) {
      const kvp = paramArray[i].split('=');
      queryParams[kvp[0]] = kvp[1];
    }

    return {
      queryParams: queryParams
    };
  }
}