import { IGbizUserInfo, IGbizDelegationInfo, IGbizDelegationUserInfo } from '@interface/gbiz.interface';

export const featureName = 'auth';
export interface State {
  loggedIn: boolean;
  userProfile: IGbizUserInfo;
  delegateLoggedIn: boolean;
  delegateUserId: number;
  delegateUser: IGbizDelegationUserInfo;
  delegationInfo: IGbizDelegationInfo;
}
export const initialState: State = {
  loggedIn: false,
  userProfile: null,
  delegateLoggedIn: false,
  delegateUserId: -1,
  delegateUser: null,
  delegationInfo: null
};
