import { Action, createReducer, on } from '@ngrx/store';
import { State, initialState } from './auth.state';
import * as AuthActions from './auth.action';

const authReducer = createReducer(
  initialState,
  on(AuthActions.setAuth, (state, { loggedIn, userProfile, delegateLoggedIn, delegateUserId, delegateUser, delegationInfo }) => ({
    ...state,
    loggedIn,
    userProfile,
    delegateLoggedIn,
    delegateUserId,
    delegateUser,
    delegationInfo,
  }))
);

// Note: The exported reducer function is necessary as function calls are not supported by the AOT compiler.
export function reducer(state: State, action: Action) {
  return authReducer(state, action);
}